<template>
  <div class="banner-area" :class="mediaSize">
    <div class="banner-area-total" v-if="mediaSize === 'large-size'">
      <div class="banner-wrapper">
        <div class="bggradient"></div>
        <div class="dotgroup">
          <img src="/assets/img/theme2/dottotgroup.png" alt="banner" data-xblocker="passed" style="visibility: visible;">
        </div>
      </div>
      <div class="flightbox">
        <img src="/assets/img/theme2/flight.png" alt="flight" data-xblocker="passed" style="visibility: visible;">
      </div>
      <div class="socialmedia-area">
        <ul>
          <li><a :href="facebookLink" target="_blank">Facebook</a></li>
          <li><a :href="twitterLink" target="_blank">Twitter</a></li>
          <li><a :href="instagramLink" target="_blank">Instagram</a></li>
        </ul>
      </div>
      <div class="searchbox-banner">
        <div class="titlebox">
          <h5>{{$t('search-tab.discover-most')}}</h5>
          <h1>{{ $t('search-tab.engaging-place') }} <span>{{ $t('search-tab.engaging-place') }}</span></h1>
        </div>
        <search-tab />
      </div>
    </div>
    <div class="banner-area-total" v-else>
      <search-tab />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BannerTheme2',
  components: {
    SearchTab: () => import('@/components/search/searchTabs/SearchTabsTheme2'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      mediaSize: 'GET_MEDIA_SIZE',
    }),
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `http://www.facebook.com/${this.hostData?.facebookLink.replace('http://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flyingcarpet_il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
  },
};
</script>

<style scope lang="scss">
img{
  max-width: 100%;
}

.banner-area.large-size{
  .banner-area-total{
    font-family: "Poppins", sans-serif;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 70px;
      height: 100%;
      border-right: 1px solid rgb(255 255 255 / 33%);
      z-index: 2;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url('/assets/img/theme2/cloud3.png');
      width: 652px;
      height: 181px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 10;
      right: 0;
      margin: auto;
    }

    .banner-wrapper{
      height: 100vh;
      max-height: 710px;
      background-color: #0061AB;
      overflow: hidden;
      position: relative;
      z-index: 1;

      &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 50%;
        background-image: url("/assets/img/theme2/cloud1.png");
        width: 414px;
        height: 168px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }
      &:before{
        content: "";
        position: absolute;
        right: 0;
        bottom: 50%;
        background-image: url("/assets/img/theme2/cloud2.png");
        width: 453px;
        height: 164px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }

      .bggradient{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 80, 140, 0.9) -3.53%, rgba(0, 66, 116, 0) 62.71%);
        z-index: 2;
      }

      .dotgroup {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 376px;
        height: 510px;
        z-index: -1;
      }
    }

    .flightbox {
      margin: auto;
      display: block;
      width: 100%;
      text-align: center;
      max-width: 440px;
      margin-top: -124px;
      position: relative;
      z-index: 1;
      right: -34px;
    }

    .socialmedia-area {
      transform: rotate(-90deg);
      position: absolute;
      left: 44px;
      bottom: 15%;
      transform-origin: bottom left;
      z-index: 6;
      max-width: 368px;
      width: 100%;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: -80px;
          width: 80px;
          height: 1px;
          background-color:#ffffff3d;
          top: 15px;
        }

        &:after {
          content: "";
          position: absolute;
          right: -99px;
          width: 99px;
          height: 1px;
          background-color: #ffffff3d;
          top: 15px;
        }

        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 16px;

          a {
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #FFFFFF;
            font-weight: 300;
          }
        }
      }
    }

    .searchbox-banner{
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 50%;
      z-index: 4;
      -ms-transition: translate(0, -50%);
      -o-transition: translate(0, -50%);
      -moz-transition: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      text-align: center;

      .titlebox{
        h5 {
          font-weight: 400;
          font-size: 46px;
          line-height: 60px;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #FFFFFF;
          span {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70.04%, #FFFFFF 296.83%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            transform: matrix(1, 0, 0, -1, 0, 0);
          }
        }

        h1 {
          font-weight: 700;
          font-size: 80px;
          line-height: 85px;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #FFFFFF;
          position: relative;
          margin: 0;
          span {
            position: absolute;
            bottom: -62%;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70.04%, #FFFFFF 296.83%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            transform: matrix(1, 0, 0, -1, 0, 0);
            line-height: 76px;
            z-index: -1;
          }
        }
      }
    }
  }
}

.banner-area.small-size{
  .banner-area-total{
    border-radius: 0px 0px 40px 40px;
    width: 100%;
    background: #0061AB;
    padding: 5px 0 75px;
    position: relative;
    z-index: 10;
    height: 370px;
    background-image: url(/assets/img/theme2/finddealafter.png);
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}
</style>
